<template>
  <div>
    <template v-if="isLoad">
      <!-- BEGIN breadcrumb -->
      <ol class="breadcrumb float-xl-end">
        <li class="breadcrumb-item"><a href="/app/dashboard">Home</a></li>
        <li class="breadcrumb-item"><a href="javascript:;">Usuário</a></li>
        <li class="breadcrumb-item active">
          <a href="javascript:;">Formulário</a>
        </li>
      </ol>
      <h1 class="page-header">Detalhes do usuário</h1>
      <template v-if="isLoad">
        <panel title="Dados do usuário" :noButton="true">
          <form @submit.prevent="onFormSubmit">
            <div class="row">
              <div class="col-6">
                <label class="form-label">Nome:</label>
                <input
                  class="form-control"
                  required
                  type="text"
                  v-model="usuario.nome"
                />
              </div>
              <div class="col-6">
                <label class="form-label">E-mail:</label>
                <input
                  class="form-control"
                  required
                  type="email"
                  v-model="usuario.email"
                />
              </div>
              <div class="col-6 mt-3">
                <label class="form-label">CPF:</label>
                <input
                  class="form-control"
                  required
                  type="text"
                  v-model="usuario.cpf"
                />
              </div>
              <div class="col-6 mt-3">
                <label class="form-label">Perfil:</label>
                <v-select
                  required
                  v-model="usuario.perfil_id"
                  :options="perfilOptions"
                  :reduce="perfil => perfil.id"
                  label="perfil"
                />
              </div>
            </div>

            <div class="w-100 d-flex justify-content-between mt-3">
              <button
                @click="() => $router.push({ name: 'usuario.listagem' })"
                class="me-2 rounded btn btn-danger btn-sm"
              >
                Voltar
              </button>
              <button
                type="submit"
                class="me-2 rounded btn btn-warning btn-sm"
                :disabled="saving"
              >
                <b-spinner v-if="saving" small class="me-2" />
                <i class="fa fa-save me-2" />
                Salvar
              </button>
            </div>
          </form>
        </panel>
      </template>
      <template v-else>
        <div class="text-center">
          <b-spinner variant="primary" />
        </div>
      </template>
    </template>
    <template v-else>
      <div class="loading"></div>
    </template>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  components: {},
  data() {
    return {
      isLoad: false,
      saving: false,
      usuario: {},
      perfilOptions: []
    }
  },
  methods: {
    async onFormSubmit() {
      this.saving = true
      let data = {
        cpf: this.usuario.cpf,
        nome: this.usuario.nome,
        email: this.usuario.email,
        perfil_id: this.usuario.perfil_id
      }
      let url = 'usuario'
      let method = 'post'
      if (this.$route.params.id) {
        method = 'put'
        url = 'usuario/' + this.$route.params.id
      }

      await axios({ method, url, data })
        .then(res => {
          return res.data
        })
        .then(res => {
          this.$notify({
            group: 'top-center',
            title: `Usuário salvo com sucesso!`,
            text: res.mensagem,
            type: 'success'
          })
          this.$router.push({ name: 'usuario.listagem' })
        })
        .catch(res => {
          this.saving = false
          this.$notify({
            group: 'top-center',
            type: 'error',
            title: 'Erro ao cadastrar usuario!',
            text: `${res.response.data.mensagem}`
          })
        })
      this.saving = false
    },
    async loadItens() {
      this.isLoad = false
      if (this.$route.params.id) {
        await axios
          .get(`usuario/${this.$route.params.id}`)
          .then(res => {
            return res.data.dados
          })
          .then(res => {
            this.usuario = res
          })
      }
      await axios
        .get(`perfil-usuario`)
        .then(res => {
          return res.data.dados
        })
        .then(res => {
          this.perfilOptions = res.data.map(item => {
            return {
              id: item.id,
              perfil: item.id + ' - ' + item.descricao
            }
          })
        })
      this.isLoad = true
    }
  },
  computed: {
    pageOptions() {
      return {
        enabled: true,
        mode: 'records',
        perPage: 20,
        position: 'bottom',
        perPageDropdown: [20, 50, 100],
        dropdownAllowAll: false,
        setCurrentPage: 1,
        jumpFirstOrLast: true,
        firstLabel: 'Primeira Página',
        lastLabel: 'Última Página',
        nextLabel: 'Próxima',
        prevLabel: 'Anterior',
        rowsPerPageLabel: 'Linhas por página',
        ofLabel: 'de',
        pageLabel: 'página', // for 'pages' mode
        allLabel: 'Todos',
        infoFn: params => `Página atual ${params.firstRecordOnPage}`
      }
    }
  },
  mounted() {
    this.loadItens()
  }
}
</script>

<style>
.vs__dropdown-toggle {
  background: #2d353c !important;
}
</style>
